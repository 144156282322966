import * as React from "react"
import AnswerLayout from "../../../components/answer-layout/answer-layout"

const AnswerPageWrong5 = () => {
  return (
    <AnswerLayout
      step="5"
      backgroundColor="violet"
      contentHTML="
        This is a bear flag pattern, the price usually drops once you see it. So unfortunately you are out of luck this time :( Your loss is <span><span id=&quot;balance-direction&quot;></span><span id=&quot;balance-change&quot;></span> dollars</span>. But don't get too upset too soon! You did a great job completing this trading simulation game.<br/>
        Click &quot;Done&quot; to claim your prize!
      "
      buttonText="Done"
      buttonLink="/results"
      balanceText="Your Balance:"
      images={{
        map: {
          show: true,
          factor: 0.04
        },
        washingtonTop: {
          show: true,
          factor: 0.08
        },
        dotsLeft: {
          show: true,
          factor: 0.12
        },
        chartDown: {
          show: true,
          factor: 0.16
        },
        dollarSmall: {
          show: true,
          factor: 0.2
        },
        bitcoin: {
          show: true,
          factor: 0.24
        },
        plusesHorizontal: {
          show: false,
          factor: 0.28
        },
        plusesHorizontalGreen: {
          show: true,
          factor: 0.28
        },
        washingtonBottom: {
          show: true,
          factor: 0.32
        },
        dollarBig: {
          show: true,
          factor: 0.36
        },
        plusesVertical: {
          show: false,
          factor: 0.4
        },
        plusesVerticalGreen: {
          show: true,
          factor: 0.4
        },
        dotsRight: {
          show: true,
          factor: 0.4
        },
        chartUp: {
          show: true,
          factor: 0.48
        },
        satoshiTop: {
          show: false,
          factor: 0.08
        },
        satoshiBottom: {
          show: false,
          factor: 0.32
        },
        bankTop: {
          show: false,
          factor: 0.08
        },
        bankBottom: {
          show: false,
          factor: 0.32
        },
        chartArrowGreenRedTop: {
          show: false,
          factor: 0.08
        },
        chartArrowGreenRedBottom: {
          show: false,
          factor: 0.32
        },
        chartArrowGreenTop: {
          show: false,
          factor: 0.08
        },
        chartArrowGreenBottom: {
          show: false,
          factor: 0.32
        },
        chartArrowYellowTop: {
          show: false,
          factor: 0.08
        },
        chartArrowYellowBottom: {
          show: false,
          factor: 0.32
        },
        questionMarkTop: {
          show: false,
          factor: 0.12
        },
        questionMarkBottom: {
          show: false,
          factor: 0.32
        },
        chartGreenUp: {
          show: false,
          factor: 0.48
        },
        chartGreenDown: {
          show: false,
          factor: 0.16
        },
        bitcoinBottom: {
          show: false,
          factor: 0.36
        },
        confetti: {
          show: false,
          factor: 0.01
        }
      }}
    ></AnswerLayout>
  )
}

export default AnswerPageWrong5